import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Collection from "../components/Collection";
import Api from "../api/api";
import { useAuth } from "../context/auth";
import Button from "../common/Button";
import Modal from "react-modal";
import avatar from "../assets/avatar.jpg";
import { FaPlusCircle, FaSpinner } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
Modal.setAppElement("#root");
const CollectionsPage = () => {
  const { user } = useAuth();
  const { id } = useParams<any>();
  const [collections, setCollections] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [modalError, setModalError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");

  const [owner, setOwner] = useState<any>();

  const history = useHistory();
  const fetchCollections = async () => {
    const res: any = await Api.getAllCollection(id);
    if (res.status === 200) {
      const fetchedCollections: any[] = res?.data?.data;
      setCollections(fetchedCollections);
    }
  };
  useEffect(() => {
    fetchCollections();
  }, [id, user]);
  const getOwner = async () => {
    const res: any = await Api.getUser(id);
    if (res.status === 200) {
      setOwner(res?.data?.data);
    }
  };
  useEffect(() => {
    getOwner();
  }, [id]);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearchQuery(e.target.value);
  };
  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setSearchQuery(tempSearchQuery);
    }
  };
  const handleAddCollection = async () => {
    if (newCollectionName.trim() === "") {
      setModalError("Collection name is required.");
      return;
    }
    setIsLoading(true);
    setModalError("");
    try {
      const res = await Api.addCollection({ name: newCollectionName });
      if (res.status === 201) {
        setIsLoading(false);
        setIsModalOpen(false);
        history.push(`/collection/manage/${res?.data?.message}`);
      }
    } catch (err) {
      console.error("Failed to create collection", err);
      setModalError("Failed to create collection. Please try again.");
      setIsLoading(false);
    }
  };
  const handleCollectionUpdate = updatedCollection => {
    setCollections(prevCollections =>
      prevCollections
        .map(collection =>
          collection._id === updatedCollection._id
            ? updatedCollection
            : collection,
        )
        .filter(collection => collection !== null)
        .sort((a, b) => {
          if (a.hidden && !b.hidden) return 1;
          if (!a.hidden && b.hidden) return -1;
          return a.position - b.position;
        }),
    );
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setNewCollectionName("");
    setModalError("");
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleDragEnd = (event: any) => {
    document.body.style.overflow = "auto";
    const { active, over } = event;
    if (active.id !== over.id) {
      setCollections(prevCollections => {
        const oldIndex = prevCollections.findIndex(
          collection => collection._id === active.id,
        );
        const newIndex = prevCollections.findIndex(
          collection => collection._id === over.id,
        );
        if (oldIndex !== -1 && newIndex !== -1) {
          const reorderedCollections = arrayMove(
            prevCollections,
            oldIndex,
            newIndex,
          );
          const updatedPositions = reorderedCollections.map(
            (collection, index) => ({
              _id: collection._id,
              position: index,
            }),
          );
          try {
            Api.updateCollectionPosition({ positions: updatedPositions });
          } catch (err) {
            console.error("Failed to update collection positions", err);
          }
          return reorderedCollections;
        } else {
          console.error("Invalid indexes for drag operation");
          return prevCollections;
        }
      });
    }
  };
  const SortableItem = ({ collection }: { collection: any }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id: collection?._id,
        disabled: collection.hidden,
      });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: collection.hidden ? "not-allowed" : "grab",
    };
    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
    };
    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        <Collection
          collection={collection}
          isSeller={user?._id === id}
          onCollectionUpdate={handleCollectionUpdate}
          onButtonClick={handleButtonClick}
          isSortable={true}
        />
      </div>
    );
  };

  const filteredCollections = collections
    .filter(collection => {
      return collection.name.toLowerCase().includes(searchQuery.toLowerCase());
    })
    .sort((a, b) => {
      return a.hidden === b.hidden ? 0 : a.hidden ? 1 : -1;
    });
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  if (
    false &&
    owner?.visibilitySettings?.collectionVisible !== true &&
    user?._id !== owner?._id
  ) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center h-screen">
          <h1 className="text-3xl">This user has disabled their collections</h1>
        </div>
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            {}
            <div className="text-center mb-8">
              <h1 className="text-3xl font-bold">Collections</h1>
            </div>
            {}
            <div className="flex justify-center mb-8">
              <input
                type="text"
                value={tempSearchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyPress}
                placeholder="Search collections..."
                className="w-full max-w-[250px] px-4 py-2 border border-gray-300 rounded-full text-center"
              />
            </div>
            {}
            <div className="flex flex-wrap justify-between items-center mb-8 gap-4">
              <div className="relative flex items-center gap-4 sm:max-w-[250px] md:max-w-[300px]">
                <div className="bg-white p-3 md:p-4 rounded-2xl border border-black w-full">
                  <div className="flex gap-3">
                    <img
                      src={owner?.avatar || avatar}
                      alt="User Avatar"
                      className="sm:w-10 sm:h-10 md:w-14 md:h-14 rounded-full object-cover"
                    />
                    <div className="flex flex-col justify-center flex-grow">
                      <p className="text-lg md:text-xl font-semibold">
                        {owner?.fname} {owner?.lname}
                      </p>
                      <p className="text-sm md:text-sm md:text-left mt-1 line-clamp-2">
                        {owner?.about}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {user?._id === id && (
                <div className="relative w-full sm:w-auto text-center sm:text-left">
                  <Button
                    className="!w-full sm:!w-fit flex items-center justify-center sm:justify-start  gap-2"
                    onClick={handleOpenModal}
                  >
                    <FaPlusCircle fill="white" />
                    Add new collection
                  </Button>
                </div>
              )}
            </div>
            {user?._id === id ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={filteredCollections.map(collection => collection._id)}
                  strategy={rectSortingStrategy}
                >
                  <div
                    className="grid sm:gap-0 md:gap-2 grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3"
                    style={{ touchAction: "none" }}
                  >
                    {filteredCollections.map(collection => (
                      <SortableItem
                        key={collection._id}
                        collection={collection}
                      />
                    ))}
                  </div>
                </SortableContext>
              </DndContext>
            ) : (
              <div className="grid justify-items-center justify-center gap-y-1 md:gap-y-4 grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                {filteredCollections.map(collection => (
                  <div
                    key={collection._id}
                    className="collection-container"
                  >
                    <Collection
                      key={collection._id}
                      collection={collection}
                      isSeller={user?._id === id}
                      onCollectionUpdate={handleCollectionUpdate}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Add New Collection"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Add New Collection</h2>
        <input
          type="text"
          value={newCollectionName}
          onChange={e => setNewCollectionName(e.target.value)}
          placeholder="Enter collection title"
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
        />
        {modalError && <p className="text-red-500 mb-4">{modalError}</p>}
        <div className="flex justify-end gap-4">
          <Button
            onClick={handleCloseModal}
            className="px-4 py-2 bg-gray-200"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddCollection}
            className="px-4 py-2 bg-blue-500 text-white"
            disabled={isLoading}
          >
            {isLoading ? <FaSpinner className="animate-spin mr-2" /> : "Create"}
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default CollectionsPage;
