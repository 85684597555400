import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";
import { CiSettings } from "react-icons/ci";
import { links } from "../seller";
import Switch from "react-switch";
import toast from "react-hot-toast";

const AccountVisibility: React.FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [data, setData] = useState({
    featuredVisible: true,
    collectionVisible: true,
    itemsVisible: true,
    messagingVisible: true,
    detailsVisible: true,
    profileVisible: true,
  });
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await Api.getUser(user?._id);
        const { visibilitySettings } = response.data.data;
        setData(visibilitySettings);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, [user]);

  const handleInputChange = async (name: string, value: boolean) => {
    let updatedData = { ...data, [name + "Visible"]: value };
    if (name === "items" && value === false) {
      updatedData = {
        ...updatedData,
        collectionVisible: false,
        featuredVisible: false,
      };
    }

    if (
      (name === "collection" || name === "featured") &&
      data.itemsVisible === false &&
      value === true
    ) {
      toast.error(`Cannot turn on ${name} visibility while items are hidden`);
      return;
    }
    setData(updatedData);

    try {
      const response = await Api.updateVisibilitySettings({
        visibilitySettings: updatedData,
      });

      if (response.status === 200) {
        toast.success(
          `${name.charAt(0).toUpperCase() + name.slice(1)} visibility updated successfully`,
        );
      } else {
        toast.error(`Failed to update ${name} visibility`);
      }
    } catch (error) {
      toast.error(`Failed to update ${name} visibility`);
      console.error(error);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="w-full flex gap-4 md:gap-6 flex-wrap">
              <div className="w-[192px] relative">
                <button
                  onClick={() => setIsShow(prev => !prev)}
                  className="text-sm w-full rounded-lg justify-center bg-primary text-white flex items-center gap-2 py-3 px-5"
                >
                  <CiSettings
                    size={20}
                    fill="#fff"
                  />
                  Account
                </button>
                {isShow && (
                  <div className="rounded-xl bg-white mt-2 py-2 absolute left-0 right-0 top-11 z-50">
                    {links?.map((itm, idx) => (
                      <div
                        key={idx}
                        className="flex items-center gap-2 py-3 px-5"
                      >
                        <itm.icon
                          size={16}
                          fill="var(--primary)"
                        />
                        <Link
                          to={itm.link}
                          className={`text-sm text-primary hover:underline ${
                            location.pathname === itm.link ? "underline" : ""
                          }`}
                        >
                          {itm.title}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-full md:w-[calc(100%_-_217px)]">
                <div className="bg-white rounded-2xl p-4 md:p-6">
                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        Featured
                      </p>
                      <Switch
                        checked={data?.featuredVisible}
                        onChange={() =>
                          handleInputChange("featured", !data.featuredVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.featuredVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.featuredVisible
                        ? "Your featured items are visible publicly"
                        : "Your featured items are hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        Collection
                      </p>
                      <Switch
                        checked={data?.collectionVisible}
                        onChange={() =>
                          handleInputChange(
                            "collection",
                            !data.collectionVisible,
                          )
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.collectionVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.collectionVisible
                        ? "Your collection is visible publicly"
                        : "Your collection is hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        Items
                      </p>
                      <Switch
                        checked={data?.itemsVisible}
                        onChange={() =>
                          handleInputChange("items", !data.itemsVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.itemsVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.itemsVisible
                        ? "Your items are visible publicly"
                        : "Your items are hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        Messaging
                      </p>
                      <Switch
                        checked={data?.messagingVisible}
                        onChange={() =>
                          handleInputChange("messaging", !data.messagingVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.messagingVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.messagingVisible
                        ? "Your messaging is visible publicly"
                        : "Your messaging is hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        My Details
                      </p>
                      <Switch
                        checked={data?.detailsVisible}
                        onChange={() =>
                          handleInputChange("details", !data.detailsVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.detailsVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.detailsVisible
                        ? "Your details are visible publicly"
                        : "Your details are hidden from the public"}
                    </div>
                  </div>

                  <div className="flex gap-4 items-center">
                    <div className="flex-1 flex items-center py-5 justify-between border-b border-primary">
                      <p className="font-medium capitalize max-md:text-sm">
                        My Profile
                      </p>
                      <Switch
                        checked={data?.profileVisible}
                        onChange={() =>
                          handleInputChange("profile", !data.profileVisible)
                        }
                      />
                    </div>
                    <div
                      className={`font-medium max-md:text-xs text-sm flex-1 ${
                        data?.profileVisible ? "text-[green]" : "text-[red]"
                      }`}
                    >
                      {data?.profileVisible
                        ? "Your profile is visible publicly"
                        : "Your profile is hidden from the public"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AccountVisibility;
