import React, { useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Button from "../common/Button";
import Item from "../components/Items/Item";
import avatar from "../assets/avatar.jpg";
import truck from "../assets/profile/truck.png";
import { Link, useHistory, useParams } from "react-router-dom";
import Api from "../api/api";
import { IProps } from "../components/Items/Item";
import { useAuth } from "../context/auth";
import { FaHeart, FaRegHeart, FaSpinner } from "react-icons/fa6";
import toast from "react-hot-toast";
import Modal from "react-modal";
import AdminHeader from "../components/Admin/AdminHeader";
import { FaTimes, FaUserCog } from "react-icons/fa";
import { europeanCountries } from "../utils/constant";
import { useCurrency } from "../hooks/useCurrency";
import ImageHandler from "../components/ImageHandler";
import { isMobile } from "react-device-detect";
import { isVideo } from "../utils/helpers";
import Loader from "../common/Loader";
Modal.setAppElement("#root");
const SingleItem: React.FC = () => {
  const [item, setItem] = useState<IProps | null>(null);
  const [seller, setSeller] = useState<any>();
  const { user, isAdmin } = useAuth();
  const [imageHeight, setImageHeight] = useState<string>("80vh");
  const [zoomContainerHeight, setZoomContainerHeight] =
    useState<string>("100%");
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState<any>();
  const [isFavorite, setIsFavorite] = useState<boolean>(
    user?.favorites?.includes(item?._id) ?? false,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [lastSelectedPhoto, setLastSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/350",
  );
  const [zoomOrigin, setZoomOrigin] = useState<{
    xPercent: number;
    yPercent: number;
  }>({
    xPercent: 50,
    yPercent: 50,
  });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [includeItemReference, setIncludeItemReference] = useState(true);
  const [isImageHandlerOpen, setIsImageHandlerOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [similarItems, setSimilarItems] = useState<IProps[]>([]);
  const { id } = useParams<{ id: string }>();
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const zoomRef = useRef<HTMLImageElement>(null);
  const history = useHistory();
  const imgRef = useRef<HTMLImageElement>(null);
  const [photoHeight, setPhotoHeight] = useState<number>(0);
  const [selectedPhoto, setSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/350",
  );
  const { userCurrency, convert, convertAsync, updateUserCurrency } =
    useCurrency(user ? user : null, europeanCountries);
  const [customPrice, setCustomPrice] = useState<any>(0);
  const [customCurrency, setCustomCurrency] = useState<any>(userCurrency);
  useEffect(() => {
    getItemByID();
  }, [id]);
  useEffect(() => {
    if (item) {
      getSimilarItems();
    }
  }, [item, item?._id]);
  useEffect(() => {
    if (item?.price && item?.currency && user) {
      const temp: any = convert(item.price, item.currency, userCurrency);
      if (temp) {
        setCustomPrice(temp);
        setCustomCurrency(userCurrency);
      }
    } else {
      setCustomPrice(item?.price);
      setCustomCurrency(item?.currency);
    }
  }, [item, user]);
  async function getSimilarItems() {
    try {
      const res = await Api.getSimilarItems(id);
      if (res.status === 200) {
        setSimilarItems(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching similar items:", error);
    }
  }
  const calculateImageSize = () => {
    if (imgRef.current) {
      const { width, height } = imgRef.current.getBoundingClientRect();

      setImageSize({ width, height });
    }
  };

  useEffect(() => {
    calculateImageSize(); // Calculate image size when component mounts or image changes
    window.addEventListener("resize", calculateImageSize); // Recalculate on window resize
    return () => window.removeEventListener("resize", calculateImageSize); // Cleanup
  }, [selectedPhoto]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);
  useEffect(() => {
    getCategory(item?.category);
    getSeller(item?.uid);
    if (item?.photos && item.photos.length > 0) {
      setSelectedPhoto(item.photos[0].url);
      setLastSelectedPhoto(item.photos[0].url);
    }
  }, [item]);
  useEffect(() => {
    setIsFavorite(user?.favorites?.includes(item?._id) ?? false);
  }, [user]);
  async function getItemByID() {
    try {
      const res = await Api.getItemByID(id);
      if (res.status === 200) {
        setItem(res?.data?.data);
        if (item?.price && item?.currency && user) {
          const temp = convert(item?.price, item?.currency, userCurrency);

          setCustomPrice(temp);
        }
        setPinned(res?.data?.data.isPinned);
      }
    } catch (error) {
      console.error("Failed to fetch item:", error);
    }
  }
  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          const width = zoomRef.current.naturalWidth;
          const height = zoomRef.current.naturalHeight;
          const aspectRatio = width / height;
          if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setImageHeight("20vh");
          } else if (aspectRatio < 1.5 && window.innerWidth < 1024) {
            setImageHeight("40vh");
          } else if (aspectRatio < 1.5 && window.innerWidth > 1024) {
            setImageHeight("50vh");
          } else {
            setImageHeight("50vh");
          }
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          const width = zoomRef.current.naturalWidth;
          const height = zoomRef.current.naturalHeight;
          const aspectRatio = width / height;
          if (aspectRatio > 1.5 && window.innerWidth > 1320) {
            setZoomContainerHeight("50vh");
          } else if (aspectRatio > 1.5 && window.innerWidth > 680) {
            setZoomContainerHeight("50vh");
          } else if (aspectRatio < 0.67 && window.innerWidth < 680) {
            setZoomContainerHeight("77vh");
          } else if (aspectRatio < 1 && window.innerWidth < 680) {
            setZoomContainerHeight("53vh");
          } else if (
            aspectRatio < 1.3 &&
            aspectRatio > 0.99 &&
            window.innerWidth < 680
          ) {
            setZoomContainerHeight("40vh");
          } else if (
            aspectRatio < 1.5 &&
            aspectRatio > 1.3 &&
            window.innerWidth < 680
          ) {
            setZoomContainerHeight("40vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 680) {
            setZoomContainerHeight("30vh");
          } else if (aspectRatio > 1.3 && window.innerWidth < 680) {
            setZoomContainerHeight("35vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 680) {
            setZoomContainerHeight("40vh");
          } else if (aspectRatio < 1 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else if (
            aspectRatio < 1.3 &&
            aspectRatio > 0.99 &&
            window.innerWidth < 1024
          ) {
            setZoomContainerHeight("80vh");
          } else if (
            aspectRatio < 1.5 &&
            aspectRatio > 1.3 &&
            window.innerWidth < 1024
          ) {
            setZoomContainerHeight("70vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else if (aspectRatio > 1.3 && window.innerWidth < 1024) {
            setZoomContainerHeight("35vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else {
            setZoomContainerHeight("50vh");
          }
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  const handleFavoriteToggle = async () => {
    if (!item?._id) return;
    try {
      if (isFavorite) {
        await Api.removeFromFavorites({ id: item._id });
        toast.success(`${item?.name} removed from favorites`);
      } else {
        await Api.addToFavorites({ id: item._id });
        toast.success(`${item?.name} added to favorites`);
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Failed to toggle favorite status", error);
    }
  };
  const toggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(prev => !prev);
  };
  const handleOptionClick = async (e: React.MouseEvent, action: string) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(false);
    if (action === "Edit Item") {
      if (isAdmin()) {
        history.push(`/admin/item/manage/${id}`);
      } else {
        history.push(`/item/manage/${id}`);
      }
    } else if (action === "Delete Item") {
      setIsDeleteModalOpen(true);
    } else if (action === "Hide Item") {
      await handleHideItem();
    } else if (action === "Feature Item") {
      await handleTogglePinned();
    }
  };
  const handleDeleteItem = async () => {
    setIsDeleting(true);
    try {
      if (isAdmin()) {
        const res: any = await Api.deleteItemAdmin(id);
        if (res.status === 200) {
          setIsDeleteModalOpen(false);
          toast.success("Item deleted successfully.");
          history.push(`/seller/${user?._id}`);
          toast.success(res.message);
        }
      } else {
        const res = await Api.deleteItem(item?._id);
        if (res.status === 200) {
          setIsDeleteModalOpen(false);
          toast.success("Item deleted successfully.");
          history.push("/items");
        }
      }
    } catch (error) {
      console.error("Failed to delete item:", error);
      toast.error("Error deleting item.");
    } finally {
      setIsDeleting(false);
    }
  };
  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          setPhotoHeight(zoomRef.current.naturalHeight);
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  const handleHideItem = async () => {
    try {
      const updatedHiddenStatus = !item?.hidden;
      if (isAdmin()) {
        const res = await Api.updateItemAdmin({
          itemId: item?._id,
          hidden: updatedHiddenStatus,
        });
        if (res.status === 200) {
          setPinned(updatedHiddenStatus);
          if (res.status === 200) {
            setItem({ ...item, hidden: updatedHiddenStatus });
            toast.success(
              updatedHiddenStatus ? "Item hidden" : "Item unhidden",
            );
          }
        }
      } else {
        const res = await Api.updateItem({
          itemId: item?._id,
          hidden: updatedHiddenStatus,
        });
        if (res.status === 200) {
          setItem({ ...item, hidden: updatedHiddenStatus });
          toast.success(updatedHiddenStatus ? "Item hidden" : "Item unhidden");
        }
      }
    } catch (error) {
      console.error("Error hiding item:", error);
      toast.error("Failed to hide/unhide item.");
    }
  };
  const handleTogglePinned = async () => {
    try {
      const updatedPinnedStatus = !pinned;
      setPinned(updatedPinnedStatus);
      const res = await Api.updateItem({
        itemId: item?._id,
        isPinned: updatedPinnedStatus,
      });
      if (res.status === 200) {
        toast.success(updatedPinnedStatus ? "Item pinned" : "Item unpinned");
      }
    } catch (error) {
      console.error("Error toggling pinned status:", error);
      toast.error("Failed to pin/unpin item.");
    }
  };
  async function getSeller(uid: string | undefined) {
    if (!uid) return null;
    try {
      // @ts-expect-error temp
      const res = await Api.getUser(uid._id);
      if (res.status === 200) {
        setSeller(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
    return null;
  }
  const handleImageMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!zoomRef.current || !isZoomedIn) return;
    const { left, top, width, height } =
      zoomRef.current.getBoundingClientRect();
    const xPercent = ((e.clientX - left) / width) * 100;
    const yPercent = ((e.clientY - top) / height) * 100;
    zoomRef.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
    setZoomOrigin({ xPercent, yPercent });
  };
  const handleMouseEnterSide = (photoUrl: string) => {
    setSelectedPhoto(photoUrl);

    if (lastSelectedPhoto !== photoUrl) {
      setIsZoomedIn(false);
    }
  };

  const handleMouseLeaveSide = () => {
    setSelectedPhoto(lastSelectedPhoto);
  };
  const handleImageClickSidePanel = (photoUrl: string) => {
    if (isImageHandlerOpen) return;
    setLastSelectedPhoto(photoUrl);
    setSelectedPhoto(photoUrl);
    setIsZoomedIn(false);
  };
  const handleImageMouseLeave = () => {
    if (isZoomedIn) {
      //setIsZoomedIn(false);
    }
  };
  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isImageHandlerOpen) return;
    if (isMobile) return;
    if (!zoomRef.current) return;
    const { offsetX, offsetY } = e.nativeEvent;
    const { width, height } = zoomRef.current;
    const xPercent = (offsetX / width) * 100;
    const yPercent = (offsetY / height) * 100;
    if (isZoomedIn) {
      zoomRef.current.style.transformOrigin = "center center";
      setIsZoomedIn(false);
    } else {
      zoomRef.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      setZoomOrigin({ xPercent, yPercent });
      setIsZoomedIn(true);
    }
  };
  async function getCategory(categoryId: string | undefined) {
    if (!categoryId) return null;
    try {
      const res = await Api.getCategory(categoryId);
      if (res.status === 200) {
        setCategory(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
    return null;
  }
  const openMessageModalOrLogin = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user?._id) {
      history.push("/login");
    } else {
      setMessage(`I'm interested in this item!`);
      setIsMessageModalOpen(true);
    }
  };
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    const params: any = { receiverId: item?.uid._id, text: message };
    if (includeItemReference) {
      params.itemId = item?._id;
    }
    setIsLoading(true);
    try {
      const res = await Api.sendMessage(params);
      if (res.status === 201) {
        setIsMessageModalOpen(false);
        toast.success("Message sent successfully");
      } else {
        console.error("Failed to send message:", res.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const removeItemReference = () => {
    setIncludeItemReference(false);
    setMessage("");
  };
  const isVisible =
    seller?.visibilitySettings?.itemsVisible === true ||
    user?._id === seller?._id ||
    isAdmin();

  if (false) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center h-screen">
          <h1 className="text-3xl">This user has disabled this item</h1>
        </div>
        <Footer />
      </>
    );
  }
  return (
    <>
      {isAdmin() ? <AdminHeader /> : <Header />}
      <main>
        <div className="py-8 sm:py-0 sm:pb-[2rem] md:py-4 bg-primary2">
          <div className="relative mx-auto max-w-[1540px] px-1 w-full">
            {user && user?._id === item?.uid._id && (
              <div
                className="self-start mb-4 max-w-[120px]"
                ref={dropdownRef}
              >
                <Button
                  onClick={toggleDropdown}
                  className="flex items-center gap-1 px-2 py-1 bg-blue-600 text-white rounded"
                >
                  <FaUserCog size={14} /> Manage
                </Button>
                {dropdownVisible && (
                  <div className="absolute bg-white border border-gray-300 rounded-md shadow-lg mt-2 z-50">
                    <button
                      onClick={e => handleOptionClick(e, "Edit Item")}
                      className="block px-4 py-2 text-sm  lg:min-w-[150px] lg:text-lg  text-black hover:bg-gray-100 w-full text-left"
                    >
                      Edit
                    </button>
                    <button
                      onClick={e => handleOptionClick(e, "Hide Item")}
                      className="block px-4 py-2 text-sm text-black lg:text-lg  hover:bg-gray-100 w-full text-left"
                    >
                      {item?.hidden ? "Unhide" : "Hide"}
                    </button>
                    <button
                      onClick={e => handleOptionClick(e, "Feature Item")}
                      className="block px-4 py-2 text-sm text-black lg:text-lg  hover:bg-gray-100 w-full text-left"
                    >
                      {pinned ? "Unfeature" : "Feature"}
                    </button>
                    <button
                      onClick={e => handleOptionClick(e, "Delete Item")}
                      className="block px-4 py-2 text-sm text-black lg:text-lg  hover:bg-gray-100 w-full text-left"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="relative mx-auto max-w-[1560px] sm:px-0 lg:px-6 w-full">
            <div className="flex flex-wrap max-lg:gap-2">
              <div className="w-full lg:w-[55%]  lg:pr-4">
                <div
                  style={{
                    minHeight:
                      window.innerWidth < 1024 ? "calc(100vw - 20px) " : "5px",
                  }}
                  className="flex flex-wrap-reverse w-full sm:mt-0 gap-1  h-full"
                >
                  <div
                    className={`grid  gap-2 scrollbar-thumb-gray-500 scrollbar-track-gray-300 scrollbar-thin lg:overflow-y-auto max-lg:overflow-x-auto lg:h-full ${
                      (item?.photos?.length || 0) + (item?.video ? 1 : 0) > 7
                        ? "grid-cols-1 max-h-[90vh]"
                        : "grid-cols-1"
                    }`}
                  >
                    {" "}
                    <div className="flex gap-2 lg:gap-0 scrollable sm:px-1 lg:flex-col lg:h-full">
                      {item?.photos?.map((photo, idx) => (
                        <div
                          key={idx}
                          className="bg-white lg:border-4 lg:border-transparent min-w-[5.5rem] min-h-[5.5rem]  max-w-[5.5rem] max-h-[5.5rem] md:min-h-[10rem] md:min-w-[10rem] lg:min-h-[5.5rem] lg:min-w-[5.5rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                          onMouseEnter={() => handleMouseEnterSide(photo.url)}
                          onMouseLeave={handleMouseLeaveSide}
                          onClick={() => handleImageClickSidePanel(photo.url)}
                        >
                          <img
                            src={photo.url}
                            alt=""
                            className="size-full object-contain"
                          />
                        </div>
                      ))}
                      {item?.video && (
                        <div
                          className="bg-white lg:border-4 lg:border-transparent min-w-[5.5rem] min-h-[5.5rem] max-w-[5.5rem] max-h-[5.5rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                          onMouseEnter={() => handleMouseEnterSide(item.video)}
                          onMouseLeave={handleMouseLeaveSide}
                          onClick={() => handleImageClickSidePanel(item.video)}
                        >
                          <video
                            src={item.video}
                            className="size-full object-contain"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="p-0 w-full lg:w-[calc(100%_-_120px)] flex justify-center  "
                    onMouseMove={handleImageMouseMove}
                    onClick={handleImageClick}
                    onMouseLeave={handleImageMouseLeave}
                    style={{
                      minHeight: imageHeight,
                    }}
                  >
                    <ImageHandler
                      photos={item?.photos?.map(photo => photo.url) || []}
                      selectedPhoto={selectedPhoto}
                      setSelectedPhoto={setSelectedPhoto}
                      onEnlargedChange={setIsImageHandlerOpen}
                      imageSize={imageSize}
                    >
                      <div
                        className="zoom-container "
                        ref={imgRef}
                        style={{
                          minHeight: zoomContainerHeight,
                          display: "flex", // Ensures flexbox layout
                          // Centers the image vertically
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        {isVideo(selectedPhoto) ? (
                          <video
                            controls
                            className="zoomed-video"
                            style={{
                              display: "block",
                              minHeight: "50vh",
                              objectFit: "contain",
                            }}
                            src={selectedPhoto}
                          />
                        ) : (
                          <img
                            ref={zoomRef}
                            src={
                              selectedPhoto || "https://via.placeholder.com/150"
                            }
                            alt="Main"
                            className={`zoomed-image ${isZoomedIn ? "zoomed-in" : ""}`}
                            style={{
                              display: "block",
                              maxHeight: "90vh",
                              marginTop:
                                window.innerWidth < 1024 ? "0rem" : "0rem",
                              marginBottom:
                                window.innerWidth < 1024 ? "0rem" : "0",
                              cursor: isZoomedIn ? "zoom-out" : "zoom-in",
                              objectFit: "contain",
                              objectPosition:
                                window.innerWidth < 1024 ? "top" : "top",
                            }}
                          />
                        )}
                      </div>
                    </ImageHandler>
                  </div>
                </div>
              </div>
              <div className="w-full p-0 sm:px-1 lg:py-8  lg:w-[45%] lg:pl-4">
                <h2 className="text-xl md:text-2xl font-semibold mb-4">
                  {item?.name || "?"}
                </h2>
                <hr className="bg-black h-0.5" />
                <div className="p-1.5 rounded-lg bg-white text-primary font-semibold text-xl w-fit">
                  {convert(customPrice, customCurrency, userCurrency)
                    ? `${convert(customPrice, customCurrency, userCurrency)} ${userCurrency}`
                    : "No price available"}
                </div>
                <br />
                <div className="bg-white whitespace-pre-wrap rounded-lg mt-4 border border-[#00000099] p-2.5 max-md:text-sm break-words">
                  {item?.description || `No description available`}
                </div>
                <div className="mt-2 flex items-center gap-3 flex-wrap text-xs md:text-sm">
                  <p>
                    Countries:{" "}
                    {item?.countries?.map((country, idx) => (
                      <span key={idx}>
                        {country}
                        {idx < (item.countries?.length ?? 0) - 1 ? ", " : ""}
                      </span>
                    )) || "-"}
                  </p>
                  <p>
                    Categories:{" "}
                    {item?.categories?.map((categ, idx) => (
                      <span key={idx}>
                        {categ.name}
                        {idx < (item.categories?.length ?? 0) - 1 ? ", " : ""}
                      </span>
                    )) || "-"}
                  </p>
                  <p>Year: {item?.years?.join(", ") || "Not applicable"}</p>
                </div>
                {user && user._id !== item?.uid && (
                  <div className="mt-4">
                    <button
                      className="flex items-center gap-2 text-sm font-semibold text-primary hover:text-red-500 transition-colors"
                      onClick={handleFavoriteToggle}
                    >
                      {isFavorite ? (
                        <>
                          <FaHeart style={{ fill: "red" }} /> Remove from
                          favorites
                        </>
                      ) : (
                        <>
                          <FaRegHeart style={{ border: "red" }} /> Add to
                          Favorites
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="md:mt-4 px-3 flex max-md:gap-4 flex-wrap-reverse">
              <div className="w-full md:w-[55%] md:pr-8">
                <p className="md:text-lg mt-3 font-semibold mb-1">Seller</p>

                <div className="bg-white p-3 md:p-4 rounded-2xl border border-black max-w-[500px]">
                  <Link to={`/seller/${seller?._id}`}>
                    <div className="flex items-center gap-1 sm:gap-1">
                      <img
                        src={seller?.avatar || avatar}
                        alt="Seller"
                        className="size-14 object-cover"
                      />
                      <div className="flex flex-grow items-center justify-between w-full">
                        <div className="flex-grow">
                          <p
                            style={{
                              wordBreak: "break-all",
                              overflowWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                            className="text-md sm:text-sm md:text-xl line-clamp-1 font-semibold mb-1 sm:mr-[-0.8rem] md:mb-2"
                          >
                            {seller?.fname} {seller?.lname}
                          </p>
                          <p
                            className="max-md:text-sm sm:text-sm lg:text-sm overflow-hidden  sm:mr-[-0.8rem]  text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {seller?.about}
                          </p>
                        </div>
                        {user?._id !== seller?._id &&
                          seller?.visibilitySettings?.messagingVisible && (
                            <Button
                              className="!w-fit ml-3 max-h-[40px] min-w-[120px]"
                              onClick={
                                user
                                  ? openMessageModalOrLogin
                                  : () => {
                                      history.push("/auth/login");
                                    }
                              }
                            >
                              Message
                            </Button>
                          )}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="w-full md:w-[45%] md:pl-4 md:mt-9">
                <div className="bg-[#85858530] rounded-2xl p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <img
                      src={truck}
                      className="size-6"
                      alt="Purchase & delivery"
                    />
                    <span className="font-semibold max-md:text-sm">
                      Purchase & delivery
                    </span>
                  </div>
                  <p className="max-md:text-sm">
                    {seller?.deliveryOption
                      ? seller?.deliveryOption
                      : "No delivery option available"}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <br />
            {similarItems.length > 0 && (
              <div>
                <p className="text-2xl md:text-3xl font-bold mb-4">
                  Similar Items
                </p>
                <div className="p-0 md:p-5 rounded-2xl bg-[#D8E8FF]">
                  <div className="grid gap-2 md:gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                    {similarItems.map((iter, idx) => (
                      <Item
                        key={iter._id}
                        isPinned={iter.isPinned}
                        isFeatured={iter.isFeatured}
                        isCountry={iter.isCountry}
                        isSeller={iter.isSeller}
                        name={iter.name}
                        user_data={iter.uid}
                        uid={iter.uid._id}
                        description={iter.description}
                        country={iter.country}
                        photos={iter.photos}
                        currency={userCurrency}
                        price={
                          iter?.price && iter?.currency
                            ? convert(iter?.price, iter?.currency, userCurrency)
                            : 0
                        }
                        year={iter.year}
                        years={iter.years}
                        id={iter._id}
                        hidden={iter.hidden}
                        category={iter.categories}
                        createdAt={iter.createdAt}
                        updatedAt={iter.updatedAt}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Modal
        isOpen={isMessageModalOpen}
        onRequestClose={() => setIsMessageModalOpen(false)}
        contentLabel="Send Message"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto md:min-w-[40%] mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="mb-[0.5rem]">
          Send a Message to {seller?.fname || "Seller"}
        </h2>
        {}
        {includeItemReference && (
          <div className="flex items-center gap-1 mb-4 relative">
            <img
              src={item?.photos?.[0]?.url || "https://via.placeholder.com/150"}
              alt={item?.name}
              className="w-20 h-20 object-cover"
            />
            <div>
              <p className="font-semibold">{item?.name}</p>
              <p className="text-sm">{item?.description}</p>
            </div>
            {}
            <button
              onClick={removeItemReference}
              className="absolute top-0 right-1 p-1 text-gray-500 hover:text-red-500"
            >
              <FaTimes />
            </button>
          </div>
        )}
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Type your message here..."
          className="w-full p-2 border border-gray-300 rounded"
          rows={5}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={() => {
              setIsMessageModalOpen(false), setIncludeItemReference(true);
            }}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Item"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
        <p>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteItem}
            className="px-4 py-2 bg-red-500 text-white"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              "Yes, Delete"
            )}
          </Button>
        </div>
      </Modal>
      <Footer />
    </>
  );
};
export default SingleItem;
