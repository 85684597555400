import { Cloudinary } from "@cloudinary/url-gen";

// Create your Cloudinary instance
export const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  },
});

export const extractPublicIdFromUrl = url => {
  try {
    if (!url) {
      url = process.env.REACT_APP_FALLBACK_IMAGE as string;
    }
    const parts = url?.split("/");
    const fileNameWithExtension = parts[parts?.length - 1];
    const publicId = fileNameWithExtension?.split(".")[0];
    return publicId;
  } catch (e) {
    console.log(e);
  }
};
export const isVideo = (url: string) => {
  if (typeof url !== "string") {
    return false;
  }
  const videoExtensions = ["mp4", "webm", "ogg"];
  const extension = url?.split(".")?.pop();
  return videoExtensions?.includes(extension || "");
};
