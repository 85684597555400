import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../common/Button";
import { FaUserCog, FaSpinner } from "react-icons/fa";
import Modal from "react-modal";
import Api from "../api/api";
import { useAuth } from "../context/auth";

interface CollectionData {
  _id: string;
  name?: string;
  items?: any[];
  hidden?: boolean;
  position?: number;
  user?: string;
}

interface CollectionProps {
  collection: CollectionData;
  isSeller: boolean;
  isAdmin?: boolean;
  onManage?: (itemId: string) => void;
  onCollectionUpdate?: (updatedCollection: CollectionData) => void;
  openDropdownId?: string | null;
  setOpenDropdownId?: (id: string | null) => void;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isSortable?: boolean;
  onDelete?: (itemId: string) => void;
  onSelectItem?: any;
  isSelected?: boolean;
}

const Collection: React.FC<CollectionProps> = ({
  collection,
  isSeller,
  onManage,
  onCollectionUpdate,
  isSortable,
  onDelete,

  onButtonClick,
  isSelected,
  onSelectItem,
}) => {
  const itemsWithPlaceholders = collection.items?.slice(0, 4) ?? [];
  const itemsToShow = [...itemsWithPlaceholders];
  while (itemsToShow.length < 4) {
    itemsToShow.push({
      _id: `placeholder-${itemsToShow.length}`,
      photos: [{ url: "https://via.placeholder.com/150" }],
      name: "Placeholder",
    });
  }
  const { isAdmin } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState<any>();
  const history = useHistory();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [mouseDownPosition, setMouseDownPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [user, setUser] = useState<any>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectItem(collection._id!, e.target.checked);
  };
  const toggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(prev => !prev);
  };

  const handleOptionClick = async (e: React.MouseEvent, action: string) => {
    e.preventDefault();
    e.stopPropagation();

    setDropdownVisible(false);

    if (action === "Add Items") {
      if (isAdmin()) {
        history.push(`/collection/manage/${collection._id}`);
      } else {
        history.push(`/collection/manage/${collection._id}`);
      }
    } else if (action === "Delete Collection") {
      setIsDeleteModalOpen(true);
    } else if (action === "Hide Collection") {
      await handleHideCollection();
    }
  };
  async function fetchUser() {
    try {
      const res = await Api.getUser(collection.user);
      if (res.status === 200) {
        setUser(res?.data?.data);
      }
    } catch (error) {
      console.error("Failed to fetch user", error);
    }
  }
  useEffect(() => {
    if (isAdmin()) {
      fetchUser();
    }
  }, [collection.user]);
  const handleDeleteCollection = async () => {
    setIsDeleting(true);
    try {
      if (isAdmin()) {
        await Api.deleteCollectionAdmin(collection._id);
      } else {
        await Api.deleteCollection(collection._id);
      }
      if (onCollectionUpdate) {
        onCollectionUpdate(collection); // Notify parent about the deletion
      }
      setIsDeleteModalOpen(false); // Close the modal after deletion
    } catch (error) {
      console.error("Failed to delete collection", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleHideCollection = async () => {
    const updatedHiddenStatus = !collection.hidden;
    const data = { collectionId: collection._id, hidden: updatedHiddenStatus };

    try {
      if (!isAdmin()) {
        const res = await Api.updateCollection(data);
        if (res.status === 200) {
          const updatedCollection = {
            ...collection,
            hidden: updatedHiddenStatus,
          };
          if (onCollectionUpdate) {
            onCollectionUpdate(updatedCollection);
          }
        }
      } else {
        const res = await Api.updateCollectionAdmin(data);
        if (res.status === 200) {
          const updatedCollection = {
            ...collection,
            hidden: updatedHiddenStatus,
          };
          if (onCollectionUpdate) {
            onCollectionUpdate(updatedCollection);
          }
        }
      }
    } catch (error) {
      console.error("Failed to hide collection", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const handleMouseDown = (e: React.MouseEvent) => {
    setMouseDownPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    if (mouseDownPosition) {
      const dx = e.clientX - mouseDownPosition.x;
      const dy = e.clientY - mouseDownPosition.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < 5) {
        if ((e.target as HTMLElement).closest(".manage-button")) {
          e.stopPropagation();
        }
      }
    }
  };

  if (collection.hidden && !isSeller) {
    return null;
  }

  const truncateTitle = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength - 3) + "...";
    }
    return title;
  };
  const handleMouseDownDiv = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent the blur event when clicking inside the dropdown
  };
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Check if focus is within the dropdown and prevent closing if so
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.relatedTarget as Node)
    ) {
      setDropdownVisible(false); // Close the dropdown
    }
  };
  const containerContent = (
    <>
      {isSeller && (
        <div
          className="absolute top-1 left-1"
          ref={dropdownRef}
        >
          <Button
            onClick={e => {
              onButtonClick?.(e);
              toggleDropdown(e);
            }}
            className="flex items-center gap-1 !text-[10px] !px-2 !py-[1px] manage-button"
          >
            <FaUserCog
              fill="white"
              size={12}
            />{" "}
            Manage
          </Button>
          {dropdownVisible && (
            <div
              tabIndex={-1} // Make the dropdown focusable
              onBlur={handleBlur}
              className="absolute top-8 left-0 bg-white border border-gray-300 rounded-md shadow-lg z-10"
            >
              {isSortable && (
                <button
                  onClick={() => history.push(`/collection/${collection._id}`)}
                  className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                >
                  Go to Collection
                </button>
              )}
              <button
                onClick={e => handleOptionClick(e, "Add Items")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                Add Items
              </button>
              <button
                onClick={e => handleOptionClick(e, "Hide Collection")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                {collection.hidden ? "Unhide Collection" : "Hide Collection"}
              </button>
              <button
                onClick={e => handleOptionClick(e, "Delete Collection")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                Delete Collection
              </button>
            </div>
          )}
        </div>
      )}

      {isAdmin() && (
        <div className="absolute top-1 right-1 z-50">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleCheckboxChange}
            onClick={e => e.stopPropagation()}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
      )}
      {isAdmin() && (
        <div
          className="flex ml-2 items-center gap-2 mb-1"
          style={{ paddingTop: "2rem" }}
        >
          {user?.country && (
            <img
              className="w-[20px] sm:max-w-[15px] md:min-w-[20px] rounded"
              src={`https://flagcdn.com/32x24/${user?.country?.toLowerCase()}.png`}
              alt={`${user?.country} flag`}
            />
          )}
          <p
            style={{
              fontSize: window.innerWidth < 760 ? "0.65rem" : "0.9rem",
            }}
            className="overflow-hidden line-clamp-1 text-sm md:text-xl lg:text-sm font-semibold"
          >
            {user?.fname} {user?.lname}
          </p>
        </div>
      )}
      <p
        className={`pb-1 sm:text-sm font-medium border-b mb-2 sm:mr-[0rem] md:mr-1 sm:ml-[0.1rem] md:ml-1.5 line-clamp-1 font-semibold ${
          isSeller && !isAdmin() ? "mt-8" : "mt-2"
        }`}
        style={{
          fontSize: window.innerWidth < 760 ? "0.7rem" : "1.1rem",
        }}
      >
        {collection.name}
      </p>

      <div className="p-0 bg-white  my-0 min-h-[100px] md:min-h-[200px] lg:min-h-[250px] overflow-hidden">
        {itemsToShow.length === 3 ? (
          <div className="grid grid-cols-2 gap-2 h-full">
            {itemsToShow.slice(0, 2).map(item => (
              <div
                key={item._id}
                className="bg-[#D8E8FF] aspect-square p-1 flex  justify-center items-center overflow-hidden"
                style={{ marginBottom: "0rem" }}
              >
                <img
                  src={
                    item.photos?.[0]?.url || "https://via.placeholder.com/150"
                  }
                  alt={item.name}
                  className="max-w-full max-h-full object-cover"
                />
              </div>
            ))}
            <div className="col-span-2 flex justify-center items-center overflow-hidden">
              <img
                src={
                  itemsToShow[2].photos?.[0]?.url ||
                  "https://via.placeholder.com/150"
                }
                alt={itemsToShow[2].name}
                className="max-w-full max-h-full object-cover"
              />
            </div>
          </div>
        ) : itemsToShow.length === 2 ? (
          <div className="flex flex-col  sm:max-h-[100px] justify-center gap-2 h-full">
            {itemsToShow.map(item => (
              <div
                key={item._id}
                className="bg-[#D8E8FF] aspect-square flex justify-center items-center overflow-hidden"
              >
                <img
                  src={
                    item.photos?.[0]?.url || "https://via.placeholder.com/150"
                  }
                  alt={item.name}
                  className="w-full h-full"
                  style={{ display: "block", objectFit: "fill" }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div
            className={`grid   ${
              itemsToShow.length === 1
                ? "grid-cols-1 grid-rows-1"
                : "grid-cols-2 grid-rows-2"
            } h-full  aspect-square`}
          >
            {itemsToShow.map(item => (
              <div
                key={item._id}
                className="bg-[#D8E8FF]  w-full h-full aspect-square  flex justify-center items-center overflow-hidden"
                style={{}}
              >
                <img
                  src={
                    item.photos?.[0]?.url || "https://via.placeholder.com/150"
                  }
                  alt={item.name}
                  className="w-full h-full  object-cover"
                  style={{ display: "block" }}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      <p className="text-center text-xs md:text-base text-primary">
        {collection.items?.length ?? 0} total items
      </p>
    </>
  );

  return (
    <div className={collection.hidden && isSeller ? "opacity-50" : ""}>
      {isSortable ? (
        <div
          className="border border-gray-300 relative block bg-white rounded-xl sm:m-1 md:m-3 md:max-w-[75%] hover:transition-all hover:shadow-[#00000033_0px_0px_10px_-2px]"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          {containerContent}
        </div>
      ) : (
        <Link
          to={`/collection/${collection._id}`}
          className="border border-gray-300 relative block bg-white rounded-xl sm:m-0 md:m-0   hover:transition-all hover:shadow-[#00000033_0px_0px_10px_-2px]"
        >
          {containerContent}
        </Link>
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Collection"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
        <p>
          Are you sure you want to delete this collection? This action cannot be
          undone.
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteCollection}
            className="px-4 py-2 bg-red-500 text-white"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              "Yes, Delete"
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Collection;
