import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import logo from "../assets/logo.svg";
import { LINKS, REACT_APP_KEY_NAME } from "../utils/constant";
import Sidebar from "./Sidebar";
import { useAuth } from "../context/auth";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
const Header: React.FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const history = useHistory();
  const activeLink = (path: string) => {
    if (location.pathname === path) return "text-primary font-medium";
    return "text-main";
  };

  const handleLogout = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    history.push("/");
    window.location.reload();
  };

  useEffect(() => {
    setToggleSidebar(false);
    // @ts-expect-error ts-ignore
    document.querySelector("body").style.overflowY = "scroll";
  }, [location.pathname]);

  return (
    <header className="bg-white">
      <div className="max-w-[1367px] mx-auto px-4 xl:px-6 w-full flex gap-3 justify-between items-center py-1 md:py-2 relative z-20">
        <Sidebar
          user={user}
          handleLogout={handleLogout}
          toggle={toggleSidebar}
          setToggle={setToggleSidebar}
          activeLink={activeLink}
        />

        <Link to="/">
          <img
            className="w-[140px] md:w-[200px]"
            src={logo}
            alt="Numisnest Logo"
          />
        </Link>

        <FaBarsStaggered
          size={24}
          fill="var(--primary)"
          className="md:hidden cursor-pointer max-w-[50%]"
          onClick={() => [
            setToggleSidebar(true),
            // @ts-expect-error ts-ignore
            (document.querySelector("body").style.overflowY = "hidden"),
          ]}
        />

        <div className="flex items-center gap-3 max-lg:hidden">
          {/* This will ensure the links are in a row */}
          <div className="flex gap-6">
            {LINKS?.map((itm, idx) => (
              <div
                className="relative"
                key={idx}
              >
                {/* Home Link with Dropdown */}
                {itm.path === "/" ? (
                  <div
                    onMouseEnter={() => setDropdownVisible(true)}
                    onMouseLeave={() => setDropdownVisible(false)}
                  >
                    <Link
                      to={itm.path}
                      className={`max-sm:hidden parent-link relative text-base 2xl:text-xl hover:text-primary p-4 hover:transition ${activeLink(
                        itm.path,
                      )}`}
                    >
                      {itm.title}
                    </Link>
                    {dropdownVisible && (
                      <div className="absolute top-full left-0 bg-white shadow-lg border rounded-lg mt-1 z-10">
                        <Link
                          to="/items"
                          className="block px-6 py-4 text-lg  text-main hover:bg-gray-100 hover:text-primary transition-all"
                        >
                          Items
                        </Link>
                        <Link
                          to="/sellers"
                          className="block px-6 py-4 text-lg  text-main hover:bg-gray-100 hover:text-primary transition-all"
                        >
                          Sellers
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    key={idx}
                    to={itm.path}
                    className={`max-sm:hidden parent-link relative text-base 2xl:text-xl hover:text-primary p-4 hover:transition ${activeLink(
                      itm.path,
                    )}`}
                  >
                    {itm.title}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="flex gap-4 max-md:hidden">
          {!user ? (
            <>
              <Link
                to="/auth/login"
                className="rounded-lg border border-primary text-primary bg-[#ffffff] text-base text-center px-4 py-2"
              >
                Login
              </Link>
              <Link
                to="/auth/signup"
                className="rounded-lg border border-primary bg-primary text-[#ffffff] text-base text-center px-4 py-2"
              >
                Sign up
              </Link>
            </>
          ) : (
            <>
              <div
                onClick={handleLogout}
                className="cursor-pointer rounded-lg border border-primary text-primary bg-[#ffffff] text-base text-center px-4 py-2"
              >
                Log out
              </div>
              <Link
                to={`/seller/${user._id}`}
                className="cursor-pointer rounded-lg border border-primary bg-primary text-[#ffffff] text-base text-center px-4 py-2"
              >
                Profile
              </Link>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
