import React, { useEffect, useState } from "react";
import Api from "../../api/api"; // Your backend API calls
import AdminHeader from "../../components/Admin/AdminHeader";

const AdminDashboard = () => {
  const [data, setData] = useState<any>({
    usersOverview: {},
    itemsOverview: {},
    websitePerformance: {},
  });

  useEffect(() => {
    async function fetchStatistics() {
      const response = await Api.getStatistics(); // Call your backend
      if (response.status === 200) {
        setData(response.data);
      }
    }
    fetchStatistics();
  }, []);

  return (
    <>
      <AdminHeader />
      <div className="container mx-auto p-6 space-y-6">
        {/* Header */}
        <h1 className="text-3xl font-semibold text-gray-800">
          Admin Dashboard
        </h1>

        {/* Overview Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* User Overview */}
          <OverviewCard
            title="User Overview"
            stats={[
              { label: "Total Users", value: data?.usersOverview?.totalUsers },
              {
                label: "New Sign-Ups (24h)",
                value: data?.usersOverview?.newUsers?.["24h"],
              },
              {
                label: "New Sign-Ups (7d)",
                value: data?.usersOverview?.newUsers?.["7d"],
              },
              {
                label: "New Sign-Ups (30d)",
                value: data?.usersOverview?.newUsers?.["30d"],
              },
              {
                label: "Active Users (24h)",
                value: data?.usersOverview?.activeUsers,
              },
            ]}
          />

          {/* Item Overview */}
          <OverviewCard
            title="Item Overview"
            stats={[
              { label: "Total Items", value: data?.itemsOverview?.totalItems },
              {
                label: "New Items (24h)",
                value: data?.itemsOverview?.newItems?.["24h"],
              },
              {
                label: "New Items (7d)",
                value: data?.itemsOverview?.newItems?.["7d"],
              },
              {
                label: "New Items (30d)",
                value: data?.itemsOverview?.newItems?.["30d"],
              },
              {
                label: "Pending Items",
                value: data?.itemsOverview?.pendingApprovals,
              },
              {
                label: "Hidden Items",
                value: data?.itemsOverview?.hiddenItems,
              },
            ]}
          />

          {/* Website Overview */}
          <OverviewCard
            title="Website Overview"
            stats={[
              {
                label: "Total Visits (24h)",
                value: data?.websitePerformance?.visits?.["24h"],
              },
              {
                label: "Total Visits (7d)",
                value: data?.websitePerformance?.visits?.["7d"],
              },
              {
                label: "Total Visits (30d)",
                value: data?.websitePerformance?.visits?.["30d"],
              },
              {
                label: "Average Load Time",
                value: data?.websitePerformance?.averagePageLoadTime,
              },
              {
                label: "Top Countries",
                value: "Not Available",
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

const OverviewCard = ({ title, stats }) => (
  <div className="bg-white shadow rounded-lg p-6">
    <h2 className="text-xl font-semibold text-gray-800 mb-4">{title}</h2>
    <ul className="space-y-3">
      {stats.map((stat, idx) => (
        <li
          key={idx}
          className="flex justify-between text-gray-600"
        >
          <span>{stat.label}</span>
          <span className="font-bold">{stat.value || "-"}</span>
        </li>
      ))}
    </ul>
  </div>
);

export default AdminDashboard;
