import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import Auth from "../../layout/Auth";
import Input from "../../common/Input";
import { ISignupData } from "../../types/auth";
import Button from "../../common/Button";
import Select from "../../common/Select";
import { countries } from "../../utils/constant";
import Textarea from "../../common/Textarea";
import Api from "../../api/api";

const initialData = {
  fname: "",
  lname: "",
  email: "",
  country: "",
  phone: "",
  about: "",
  password: "",
  confirmPassword: "",
};

const Signup: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState<ISignupData>({ ...initialData });
  const fetchUserLocation = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const locationData = await response.json();
      const countryCode = locationData?.country || "IL";
      setData(prev => ({ ...prev, country: countryCode }));
    } catch (error) {
      console.error("Failed to fetch user location:", error);
      setData(prev => ({ ...prev, country: "IL" }));
    }
  };
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    setIsDisable(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmPassword, ..._data } = data;
      const res = await Api.signup({
        ..._data,
        isNew: false,
        type: type || "seller",
      });
      if (res.status === 200) {
        history.replace("/auth/login");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);
  useEffect(() => {
    fetchUserLocation();
  }, []);
  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);
  return (
    <Auth
      title="Create an Account"
      submitHandle={handleSubmit}
      showLogo={false}
    >
      <div className="max-w-full mt-[-1rem]">
        {/* Flexbox for name fields */}
        <div className="flex flex-col sm:flex-row sm:gap-4 sm:items-center mt-4">
          <Input
            className="flex-1 mb-1 sm:mt-[0.3rem] sm:mb-0"
            title="First Name*"
            name="fname"
            value={data?.fname}
            onChange={handleInputChange}
            placeholder="Enter First Name"
            required
          />
          <Input
            className="flex-1 sm:mt-[0.3rem] sm:mb-0"
            title="Last Name*"
            name="lname"
            value={data?.lname}
            onChange={handleInputChange}
            placeholder="Enter Last Name"
            required
          />
        </div>

        <Input
          type="email"
          title="Email*"
          name="email"
          value={data?.email}
          onChange={handleInputChange}
          placeholder="Enter Email"
          required
          className="my-[-0.7rem] mt-[0rem]"
        />

        <Select
          title="Country*"
          name="country"
          value={data?.country}
          onChange={handleInputChange}
          required
          options={countries}
          className="my-1"
        />

        <div className="relative my-2">
          <label className="capitalize block mb-1 text-secondary text-[15px]">
            Phone Number
          </label>
          <PhoneInput
            country={data?.country?.toLowerCase()}
            containerClass="bg-[#fff] border border-[#A6A9ACB2] rounded-sm text-sm w-full"
            inputClass="!h-[45px] !w-full !bg-[#fff] !border-none !rounded-sm !pl-10"
            buttonClass="!bg-[#fff] !border-none !rounded-sm"
            onChange={(value: string) =>
              setData(prev => ({ ...prev, phone: value }))
            }
            value={data.phone}
          />
        </div>

        <Textarea
          title="About"
          name="about"
          value={data?.about}
          onChange={handleInputChange}
          placeholder="Tell us about you and what you are collecting"
          className="my-[-1rem] mt-[-0.2rem]"
        />

        <Input
          type="password"
          title="Password*"
          name="password"
          value={data?.password}
          onChange={handleInputChange}
          placeholder="Enter Password"
          required
          className="my-1"
        />

        <Input
          type="password"
          title="Confirm Password*"
          name="confirmPassword"
          value={data?.confirmPassword}
          onChange={handleInputChange}
          placeholder="Confirm Password"
          required
          className="mt-[-0.1rem]"
        />

        <Button
          className="mt-3 mb-2"
          type="submit"
          disabled={isDisable}
        >
          Signup
        </Button>

        <div className="text-sm flex gap-1 items-center justify-center text-secondary mt-4">
          Already a member?
          <Link
            to="/auth/login"
            className="text-primary font-medium"
          >
            Log in
          </Link>
        </div>
      </div>
    </Auth>
  );
};

export default Signup;
